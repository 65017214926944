import * as React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';

const MainWrapper = styled.main`
  height: 100vh;
  background-image: url(/img/top-shape.svg);
  background-repeat: no-repeat;
  background-size: contain;
  @media(orientation: portrait) {
    background-image: url(/img/top-shape-mobile.svg);
  }
`;

const InnerWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-image: url(/img/bottom-shape.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  @media(orientation: portrait) {
    background-image: url(/img/bottom-shape-mobile.svg);
  }
`;

const NotFoundPage = () => (
  <MainWrapper>
    <Helmet title="Strony nie znaleziono - iqcode" />
    <InnerWrapper>
      <div style={{ padding: '15px' }}>
        <h1>
          <span style={{ fontSize: '10vh' }}>404</span>
        </h1>
        <h2>Strony nie znaleziono</h2>
        <p>Przepraszamy, żądana strona nie została odnaleziona.</p>
        <Link className="btn" to="/">
          Strona główna
        </Link>
      </div>
    </InnerWrapper>
  </MainWrapper>
);

export default NotFoundPage;
